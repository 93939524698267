import React, { useState } from 'react';
import Reveal from 'react-reveal';
import Layout from "../components/layouts";
import { Parallax } from 'react-parallax';
import header from "../images/header-sustatainable-gift.jpg";
import logo from "../images/header-sustainable-logo.png";
const HolidayTips = () => {

  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [feedback, setFeedback] = useState({
    message: "",
    type: null,
  }); 
  const messageClasses = `contact-message ${feedback.type}`;

  const handleSubmit = ((e) => {
    e.preventDefault();
    const formData  = new FormData();

    for(const name in e) {
      formData.append(name, e[name]);
    }

    if (fName !== "" && lName !== "" && email !== "") {
        let regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        if (!regex.test(email)) {
          setFeedback({
            message: 'Please enter a valid E-mail',
            type: 'error'
          });
          return;
        } else {
          fetch("https://globalinheritance.us2.list-manage.com/subscribe/post-json?&c=?", {
            method: "POST",
            body: JSON.stringify(formData),
            mode: "no-cors",
          }).then(() => {
            setFeedback({
              message: 'Thank you! Your information has been sent',
              type: 'success'
            });
          }).catch((error) => {
            setFeedback({
              message: error.message,
              type: "error",
            });
          });
        }
    } else {
      setFeedback({
        message: 'Please fill out all fields',
        type: 'error'
      });
    }
  });

  return (
    <Layout navBg>
    <div className="carpoolchella-page">
      <Parallax className="page-header" bgImage={header} strength={100}>
          <div style={{height: '100vh', maxHeight: '575px'}}>
            <h1 className="page-header-title white"></h1>
            <img src={logo} className="sustainable-logo" />
          </div>
      </Parallax>
        <div className="carpoolchella-inner">
          <div className="carpoolchella-description">
          <h1>A Global Inheritance Holiday Special </h1>
              <p>
Don’t be a Scrooge this December by making Mom Earth work overtime – you can celebrate the holidays and support the planet by getting creative with your gift giving (and wrapping!). Our team at Global Inheritance headquarters organized a few tips to help you reduce waste and look less trashy in front of friends and family.  We are even hosting a Gift Wrap Battle where we introduce the best sustainable wrappers on Earth. Thank you for all your support this year and we wish all of you happy holidays! </p>
          </div>
      </div>
      
        <Reveal effect="animated fadeIn">

        <div className="carpoolchella-inner">
        
          <div className="carpoolchella-description half">
          <h1>Presents to Impress Your Mother (Earth&nbsp;that&nbsp;is)</h1>
            <ul className="number">
                <li>
                  <p>It can seem cliche, but spending time with the ones you love, like, or manage to tolerate a few times a year is the meaningful ingredient in giving Gift Experiences. Try a dance class, night at the movies, concert, spa day, art show, or just a cozy night binge-watching some guilty pleasure television.</p>
                  <div className="info-box">
                    <p>Check out <a href="https://skift.com/blog/consumer-preference-for-experiential-gifts-peaks-survey/#:~:text=92%25%20of%20participants%20said%20they,an%20initial%2062%25%20in%202021" target="_blank">this study</a> that found that 92% of people prefer receiving experiences over physical gifts</p>
                  </div>
                </li>
                <li>
                  <p>It really is better to give than to receive. Make a donation to a cause in the name of the gift recipient.  Many incredible NGOs even offer gifting programs, like naming an animal at a rescue shelter, or directly offer potential gift items, like Global Inheritance’s full-size and mini Art of Recycling recycling bins.</p>
                  <div className="info-box">
                    <p>Use <a href="https://greatnonprofits.org/" target="_blank">this tool</a> to find nonprofits in your neighborhood for a bigger impact!</p>
                  </div>
                </li>
                <li>
                  <p>Give it up for those in your hood. Shopping for gifts at your local businesses and supporting community craftspeople is also an investment in your future. </p>
                  <div className="info-box">
                    <p>Read <a href="https://www.entrepreneur.com/growing-a-business/how-to-support-small-businesses-this-holiday-season/466065" target="_blank">this article</a> from Entrepreneur for more ideas on how to support your local businesses. </p>
                  </div>
                </li>
                <li>
                  <p>Don’t look trashy this holiday season - choose gifts from sustainable or plastic-free brands or if you’ve got the skills, create your own! </p>
                  <div className="info-box">
                    <p>Check out <a href="https://packagefreeshop.com/" target="_blank">Package Free Shop</a> to shop the best plastic-free gifts!</p>
                  </div>
                </li>
                <li>
                  <p>Pre-loved gifts never go out of fashion. Find gems at your local thrift store or gift a shopping spree to discover the next thrifted trend.</p>
                  <div className="info-box">
                    <p>Try using this <a href="https://www.thethriftshopper.com/" target="_blank">Thrift Store locator</a> to find locations that are convenient for you!</p>
                  </div>
                </li>
                <li>
                  <p>Create your own gift certificate to deliver experiences that nobody else could. Whether it’s a homemade dinner, mixtape playlist, yard work, sober driver night, life coach session, coffee date, or anything your recipient might need. The only limit is your imagination.</p>
                  <div className="info-box">
                    <p>Canva makes it easy to design your perfect gift certificate. Try it <a href="https://www.canva.com/create/gift-certificates/" target="_blank">here</a>!</p>
                  </div>
                </li>
                <li>
                  <p>Gift a plant, or plant a tree (or three!) to support taking carbon out of the atmosphere!</p>
                  <div className="info-box">
                    <p>Check out the organization <a href="https://onetreeplanted.org/products/gift-trees" target="_blank">One Tree Planted</a></p>
                  </div>
                </li>
              </ul>
          </div>
          <div className="carpoolchella-description half">
          <h1>Make Your Wraps Matter</h1>
              <ul className="number">
                  <li>
                    <p>A gift bag has unlimited lives if you treat ‘em right – always reach for last year's bags, tissue paper and ribbons first!</p>
                    <div className="info-box">
                      <p>Every year an estimated <a href="https://wrappily.com/blogs/sustainability/unwrapping-our-impact-2021#:~:text=Every%20year%20an%20estimated%204.6,the%20actual%20fiber%20exceedingly%20difficult." target="_blank">4.6 million pounds of wrapping paper</a> is used in the U.S., most of which ends up in the landfill! Feel confident about the impact you're making!</p>
                    </div>
                  </li>
                  <li>
                    <p>Always go for recycled paper first - paper bags, newspaper, old magazines, posters + more.</p>
                    <div className="info-box">
                      <p>This <a href="https://www.simplenaturedecorblog.com/12-creative-ways-to-wrap-gifts-with-newspaper/" target="_blank">blog post</a> is sure to inspire some newspaper-wrapped gifts!</p>
                    </div>
                  </li>
                  <li>
                    <p>Wrap with fabric from home or from a thrift store! Joyful colors and patterns are everywhere - scarves, clothing, tote bags, and more!</p>
                    <div className="info-box">
                      <p>Explore <a href="https://www.invaluable.com/blog/furoshiki/" target="_blank">Furoshiki</a> - the Japanese art of using fabric to wrap items.</p>
                    </div>
                  </li>
                  <li>
                    <p>Instead of manufactured bows - opt for twine with fabric from old clothes, and make your own bows from recycled materials to show off your creativity.</p>
                    <div className="info-box">
                      <p>Check out this <a href="https://www.youtube.com/watch?v=GzLCZed1lCI" target="_blank">video tutorial</a> on how to wrap bows from recycled magazines and this <a href="https://www.nytimes.com/2020/12/05/at-home/wrapping-activity.html" target="_blank">New York Times article</a> on how to make newspaper bows!</p>
                    </div>
                  </li>
                  <li>
                    <p>Reuse cardboard shipping boxes, cereal or other sturdy boxes instead of something new.</p>
                    <div className="info-box">
                      <p>Do you still have leftover boxes? Check <a href="https://www.bobvila.com/articles/what-to-do-with-cardboard-boxes/" target="_blank">this out</a> and put them to use!</p>
                    </div>
                  </li>
                  <li>
                    <p>Add natural festive touches – a sprig of pine, dried flowers, etc. – to make your presents pop.</p>
                    <div className="info-box">
                      <p>Get some ideas <a href="https://paintsewgluechew.com/2014/11/12-days-gift-wrap-ideas-flowers-plants-day-9/" target="_blank">here</a>!</p>
                    </div>
                  </li>
                  <li>
                    <p>Use biodegradable paper tape or recycled scotch tape - it’s so worth it that you may buy a second as a gift!</p>
                    <div className="info-box">
                      <p><a href="https://www.treehugger.com/best-eco-friendly-tapes-5191708" target="_blank">This article</a> ranks the best eco-friendly tape of 2023!</p>
                    </div>
                  </li>
              </ul>
            
          </div>
        </div>
        </Reveal>

        <div className="contact" id="form">
                    <div className="form-container">
                        <div className="form-inner application-form-inner">
                            <h1>Sign up for more info</h1>
                            <form name="applicationForm" className="form application-form app-intern" onSubmit={(e) => handleSubmit(e)}>
                                <input type="hidden" name="u" value="40e700f186279051f3a238f8a"/>
                                <input type="hidden" name="id" value="0704a0a613"/>
                                <input type="hidden" name="TYPE" id="MERGE3" value="INTERN" />
                                <div className="form-field application-field">
                                    <input type="text" name="FNAME"  placeholder="first name" id="MERGE1" value={fName} onChange={(e) => setFname(e.currentTarget.value)} />
                                    <label>FIRST NAME</label>
                                </div>
                                <div className="form-field application-field">
                                    <input type="text" name="LNAME"  placeholder="last name" id="MERGE2" value={lName} onChange={(e)=> setLname(e.currentTarget.value)} />
                                    <label>LAST NAME</label>
                                </div>
                                <div className="form-field application-field">
                                    <input type="email" name="EMAIL"  placeholder="email" id="MERGE0" value={email}  onChange={(e) => setEmail(e.currentTarget.value)} />
                                    <label>EMAIL</label>
                                </div>
                               

                                <div className="submit-container">
                                    <button type="submit" className="btn-container">
                                        <div className="btn primary small">
                                        <span>Submit</span>
                                        </div>
                                    </button>
                                </div>

                                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                                    <label htmlFor="b_name">Name: </label>
                                    <input type="text" name="b_name" tabIndex={-1} value="" placeholder="Freddie" id="b_name"/>

                                    <label htmlFor="b_email">Email: </label>
                                    <input type="email"  name="b_email" tabIndex={-1} value="" placeholder="youremail@gmail.com" id="b_email"/>

                                    <label htmlFor="b_comment">Comment: </label>
                                    <textarea name="b_comment"  tabIndex={-1} placeholder="Please comment" id="b_comment"></textarea>
                                </div>

                                <div className={messageClasses}>{feedback.message}</div>
                            </form>

                        </div>
                    </div>
                        
                    {/* <a href="mailto:pb@globalinheritance.org">Partner</a>
                    <a href="mailto:pb@globalinheritance.org">Ask Questions</a> */}

                </div>
    </div>
    </Layout>
);
}

export default HolidayTips;


